export const testDevice = () => {
  const start = new Date().getTime();
  let end = 0;
  let i = 0;
  for (i = 0; i < 1000000; i += 1) {
    end = new Date().getTime();
    // time 500 milis
    if (end - start > 500) {
      break;
    }
  }
  const speed = i / (end - start);
  return speed;
};

//  export

export default { testDevice };
