import { DEFAULT_EXPANDED_STORAGE_KEY, VISITED_URLS_STORAGE_KEY, WELCOME_BUYDENTAL } from 'constants/data';

const clearStorage = () => {
  if (window && window.localStorage) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in localStorage) {
      if (!key?.startsWith('ins') && !key?.startsWith('sp')) {
        if (String(key) !== WELCOME_BUYDENTAL) {
          localStorage.removeItem(key);
        }
      }
    }
    localStorage.setItem('logout', new Date());
    localStorage.setItem(VISITED_URLS_STORAGE_KEY, '[]');
    localStorage.removeItem(DEFAULT_EXPANDED_STORAGE_KEY);
  }
};

export default {
  clearStorage,
};
