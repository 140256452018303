/* eslint-disable no-param-reassign */
import { FRONTEND_APIS } from 'constants/APIUriV2';
import { GET, POST } from './Clients';

const loadProductDetail = async ({ ctx, params = {} }) => {
  params.systemDisplay = 'BUYDENTAL';
  return GET({
    url: FRONTEND_APIS.PRODUCT_DETAIL,
    ctx,
    isBasic: true,
    params,
  });
};

const loadProductsFuzzySearch = async ({ body, ctx, isSKUIdentifier = false, ...restProps }) => {
  // hardcode buydental
  body.uniqueIdentifier = 'PRODUCT_GROUP'; // VARIANT
  if (isSKUIdentifier) {
    body.uniqueIdentifier = 'SKU';
  }
  body.filter = body.filter || {};
  body.filter.systemDisplay = 'BUYDENTAL';

  return POST({ ctx, url: FRONTEND_APIS.FUZZY_SEARCH, body, ...restProps });
};

const loadCart = async ({ ctx, params }) => GET({ ctx, url: FRONTEND_APIS.CART, params });

const searchFuzzyLite = async ({ ctx, params, body }) => {
  // hardcode buydental
  body.uniqueIdentifier = 'PRODUCT_GROUP'; // VARIANT
  body.filter = body.filter || {};
  body.filter.systemDisplay = 'BUYDENTAL';

  return POST({ ctx, url: FRONTEND_APIS.FUZZY_SEARCH_LITE, params, body });
};

export default {
  loadProductDetail,
  loadProductsFuzzySearch,
  loadCart,
  searchFuzzyLite,
};
