import { MONITOR_API } from 'constants/APIUri';
import { POST } from './Clients';

// const collectEvent = (event, metadata) => POST({ url: MONITOR_API.COLLECTOR, body: { event, metadata }, timeout: 150 });
const collectEvent = (event, metadata) => POST({ url: MONITOR_API.COLLECTOR, body: { event, metadata }, timeout: null, priority: 'low' });

const collectEvents = (events) => POST({ url: MONITOR_API.COLLECTORS, body: { datas: events }, timeout: null, priority: 'low' });
const collectEventWithBody = (event, body) => POST({ url: MONITOR_API.COLLECTOR, body: { event, ...body }, timeout: null, priority: 'low' });

export default {
  collectEvent,
  collectEvents,
  collectEventWithBody,
};
